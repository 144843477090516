import { USER_MANAGEMENT_API_URL } from '@/common/config'
import { HttpClient } from './http-client/HttpClient'
import httpProvider from './http-provider'
import { getAuth0Header } from '@/services/tokenManager'
import { IUserRole } from '@sennder/senn-node-microfrontend-interfaces'

class UserManagementService extends HttpClient {
  public async getUserRoles(id: string): Promise<IUserRole[]> {
    return this.get(`/api/users/${id}/roles`).then(
      (response: any) => response.roles
    )
  }
}

if (!USER_MANAGEMENT_API_URL) {
  throw new Error('USER_MANAGEMENT_API_URL not found')
}

export const userManagementService = new UserManagementService({
  httpProvider,
  getAuthHeader: () =>
    getAuth0Header({
      audience: 'https://api.cloud.sennder.com/usermanagement',
      scope: 'openid profile email users:read-any',
    }),
  baseURL: USER_MANAGEMENT_API_URL,
})
