<template>
  <dsf-navbar
    :dsf_collapsed="!expanded"
    :dsf_title="$t('navigation.title.expanded')"
    :dsf_collapsed_title="$t('navigation.title.collapsed')"
    :dsf_collapse_text="$t('navigation.item.collapse')"
    :dsf_expand_text="$t('navigation.item.expand')"
    @navigate="navigate"
    @expand="onExpand"
    @collapse="onCollapse"
    @logo-click="onLogoClick"
    class="nav-bar"
  >
    <app-navigation-items />
  </dsf-navbar>
</template>

<script lang="ts" setup>
import router from '@/router'
import AppNavigationItems from './AppNavigationItems.vue'

const LS_EXPAND_KEY = 'sennTMS_navbar_expand'

const expanded = Boolean(localStorage.getItem(LS_EXPAND_KEY))

const onCollapse = () => {
  localStorage.removeItem(LS_EXPAND_KEY)
}

const onExpand = () => {
  localStorage.setItem(LS_EXPAND_KEY, 'true')
}

const navigate = (e: CustomEvent) => {
  const route = e.detail
  if (route) {
    router.push({ path: route })
    window.scrollTo(0, 0)
  }
}

const onLogoClick = () => {
  router.push({ path: '/' })
}
</script>

<style scoped lang="scss">
.nav-bar {
  z-index: 15; // override Octopus Core z-index
}
</style>
