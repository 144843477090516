import { getAuth0Instance } from '@sennder/senn-node-auth0-frontend'
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js'
import { AUTH0_USE_TOKEN_POPUP } from '@/common/config'
import { auth } from '@/modules/auth'

export type TokenPrefix = 'Bearer' | 'Token'
export type TokenParts = { prefix: TokenPrefix; data: string }

export const AUTH_TOKEN_COOKIE_EXPIRE_TIME = '7D'
export const AUTH_TOKEN_COOKIE_NAME = '' // TODO: Define the right cookie name here

export async function getAuth0Token(
  options?: GetTokenSilentlyOptions,
  usePopup = false,
  throwException = false
): Promise<string | null> {
  let haveToUsePopup = usePopup

  if (options?.audience) {
    haveToUsePopup = AUTH0_USE_TOKEN_POPUP
  }

  return getAuth0TokenInternal(options, haveToUsePopup, throwException)
}

export async function getAuth0Header(
  options?: GetTokenSilentlyOptions,
  usePopup = false,
  throwException = false
): Promise<string | null> {
  const token = await auth.value?.getAuthToken(options)
  return token ? `Bearer ${token}` : null
}

async function getAuth0TokenInternal(
  options?: GetTokenSilentlyOptions,
  usePopup = false,
  throwException = false
): Promise<string | null> {
  const auth0Instance = getAuth0Instance()

  const isAuthenticated = await auth0Instance?.isAuthenticated()
  if (isAuthenticated) {
    return await auth0Instance.getToken(options, usePopup, throwException)
  }
  return null
}

export function getTokenParts(token: string): TokenParts {
  if (!token || typeof token !== 'string') {
    throw Error(
      '[tms - TokenManager]: Cant get token parts. Token should be not empty and a string'
    )
  }

  const tokenParts = token.split(' ')

  if (tokenParts.length !== 2) {
    throw Error(
      `[tms - TokenManager]: Cant get parts for token ${token}. Token format is 'Prefix ***********...'`
    )
  }

  return { prefix: tokenParts[0] as TokenPrefix, data: tokenParts[1] }
}
