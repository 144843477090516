import {
  SharedDataType,
  Tenant,
} from '@sennder/senn-node-microfrontend-interfaces'
export const ENV = process.env.VUE_APP_ENV as 'dev' | 'prod' | 'uat'
export const APP_VERSION = process.env.VUE_APP_VERSION
export const LAUNCH_DARKLY_KEY = process.env.VUE_APP_LAUNCH_DARKLY_KEY
export const LAUNCH_DARKLY_STATIC_KEY =
  process.env.VUE_APP_LAUNCH_DARKLY_STATIC_KEY
export const DATADOG_APP_ID = process.env.VUE_APP_DATADOG_APP_ID
export const DATADOG_CLIENT_TOKEN = process.env.VUE_APP_DATADOG_CLIENT_TOKEN
export const AMPLITUDE_API_KEY = process.env.VUE_APP_AMPLITUDE_API_KEY
export const LOCAL_MICROFRONTENDS = process.env.VUE_APP_LOCAL_MICROFRONTENDS
export const AUTH0_USE_TOKEN_POPUP = ENV !== 'prod'
export const HOTJAR_ID = process.env.VUE_APP_HOTJAR_ID

export const POEDITOR_API_TOKEN = 'c130a9b9ed1b9b5c5fba0392681d3c53'
export const POEDITOR_PROJECT_ID = '475297'
export const TRANSLATIONS_URL = 'https://translations.cloud.sennder.com/octopus'

export const IS_LOCALHOST = window.location.hostname === 'localhost'
export const OPERATIONS_URL = process.env.VUE_APP_OPERATIONS_URL
export const USER_MANAGEMENT_API_URL =
  process.env.VUE_APP_USER_MANAGEMENT_API_URL
export const ORCAS_URL = process.env.VUE_APP_ORCAS_URL

export const isLocalEnv = (): boolean => {
  return ENV === 'dev'
}

export function isUatEnv() {
  return ENV === 'uat'
}

export function isProductionEnv() {
  return ENV === 'prod'
}

export const TENANT: Tenant = Tenant.SENNDER
export const SHARED_DATA_TYPE = SharedDataType.SENN_TMS
export const FULLSTORY_ORGID = process.env.VUE_APP_FULLSTORY_ORGID

export const SHOW_LANGUAGE_PICKER = false
