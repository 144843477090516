import { LDContext, identify } from '@sennder/senn-node-feature-flags-frontend'
import { IUser } from '@sennder/senn-node-microfrontend-interfaces'

export const identifyUserInLaunchDarkly = async (user: IUser) => {
  const ldContext: LDContext = {
    kind: 'user',
    key: user.uuid,
    email: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    country: user.timezone,
  }
  await identify(ldContext)
  console.info('[tms - LaunchDarkly]: User is identified in LaunchDarkly')
}

export const getLaunchDarklyAnonymousContext = (): LDContext => {
  return {
    key: 'user_key',
  }
}

export const anonymizeUserInLaunchDarkly = async () => {
  await identify(getLaunchDarklyAnonymousContext())
  console.info('[tms - LaunchDarkly]: User is anonymized in LaunchDarkly')
}
