<template>
  <navigation-items
    :navigation="navigation"
    :data-to-watch="dataToWatch"
    :translate-item="translateItem"
  ></navigation-items>
</template>

<script lang="ts" setup>
import { Navigation, NavigationItems } from '@sennder/shell-utilities'

import { i18n } from '@/services/i18n'
import { navigation } from '../config/navigation'
import { getStateData } from '@/store'

const dataToWatch = [
  () => getStateData().featureFlags,
  () => getStateData().user,
  () => getStateData().language,
]

const translateItem = (item: Navigation<string>) =>
  item.kind === 'leaf'
    ? i18n.global.t(`navigation.item.${item.translationKey}`)
    : i18n.global.t(`navigation.category.${item.translationKey}`)
</script>
