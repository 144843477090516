import { watch } from 'vue'

import {
  IMicrofrontendData,
  IWidgetSharedData,
  MountFunc,
  SharedDataType,
} from '@sennder/senn-node-microfrontend-interfaces'
import {
  cloneReactiveToRaw,
  MicroFrontendLogger,
} from '@sennder/shell-utilities'

import errorsHandler from '@/services/errors-handler'
import { getStateCallbacks, getStateProviders, getStateData } from '@/store'
import { AppAnalyticsProvider } from './analyticsProvider'
import { wrapAuthWithLogging } from '@/modules/utils'
import { loggerInstance } from './logger'
import { microfrontends } from '@/config/microfrontends'

let mountFunction: MountFunc<IWidgetSharedData>
let unmount: (() => void) | null = null
let unwatch: (() => void) | null = null

function getWidgetData(): IWidgetSharedData {
  const { language, featureFlags, user, tenant, env } = getStateData()

  if (!user) {
    throw new Error(
      '[tms-shell - Component Generator]: state.data.user is not initialized'
    )
  }

  return {
    type: SharedDataType.WIDGET,
    env,
    language,
    featureFlags,
    user,
    tenant,
  }
}

async function loadCreateSearchBar(elementId: HTMLElement) {
  const searchBarModule = microfrontends['search-bar']

  mountFunction = await searchBarModule.getMountFn()

  const logger = new MicroFrontendLogger(
    {
      module: searchBarModule.npmName,
      codeOwners: 'ops-compass',
    },
    () => loggerInstance
  )

  const { getAuthHeader, getToken } = getStateCallbacks()

  const microfrontendData: IMicrofrontendData<IWidgetSharedData> = {
    data: getWidgetData(),
    callbacks: {
      ...getStateCallbacks(),
      getAuthHeader: wrapAuthWithLogging(getAuthHeader, logger),
      getAuthToken: wrapAuthWithLogging(getToken, logger),
      getToken: wrapAuthWithLogging(getToken, logger),
    },
    providers: {
      monitoring: getStateProviders().monitoring,
      logger,
      analytics: new AppAnalyticsProvider({
        module: searchBarModule.npmName,
        submodule: 'search-bar',
      }),
    },
  }

  const mountResult = mountFunction(elementId, microfrontendData)

  if (mountResult.onSharedDataChanged) {
    unwatch = watch(
      () => getStateData(),
      () => {
        if (mountResult.onSharedDataChanged) {
          mountResult.onSharedDataChanged(cloneReactiveToRaw(getWidgetData()))
        }
      },
      { deep: true }
    )
  }

  if (mountResult.unmount) {
    unmount = mountResult.unmount
  }
}

export async function initSearchBar(elementId: HTMLElement) {
  try {
    await loadCreateSearchBar(elementId)
    return true
  } catch (error: any) {
    errorsHandler(error)
    return false
  }
}

export function stopSearchBar() {
  unwatch?.()
  unmount?.()
}
