import { ShellLogger } from '@sennder/shell-utilities'
import { loggerInstance } from '.'

export const logger = new ShellLogger(
  {
    module: 'senntms-shell',
    codeOwners: 'frontend-systems',
  },
  () => loggerInstance
)
