<template>
  <dsf-accordion dsf_title="Activity Feed Debug">
    <dsf-col slot="content">
      <dsf-row dsf_gap="m">
        <dsf-form-group>
          <dsf-input
            dsf_label="entity id"
            :value="id"
            @input="(e: CustomEvent<string>) => id = e.detail"
          />
          <dsf-input
            dsf_label="entity name"
            :value="name"
            @input="(e: CustomEvent<string>) => name = e.detail"
          />
          <dsf-input
            dsf_label="Title translations key"
            :value="title"
            @input="(e: CustomEvent<string>) => title = e.detail"
          />
          <dsf-input
            dsf_label="Render root"
            :value="root"
            dsf_icon_left="Hash"
            @input="(e: CustomEvent<string>) => root = e.detail"
          />
        </dsf-form-group>
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 6px;
            margin-top: 18px;
          "
        >
          <dsf-checkbox
            dsf_label="Activities"
            :dsf_checked="activities"
            @change="(e: CustomEvent<boolean>) => (activities = e.detail)"
          />
          <dsf-checkbox
            dsf_label="Comment"
            :dsf_checked="comments"
            @change="(e: CustomEvent<boolean>) => (comments = e.detail)"
          />
        </div>
        <!-- <dsf-checkbox dsf_size="small" dsf_label="Incidents" /> -->
      </dsf-row>
      <dsf-button
        @click="mountWithWindow"
        dsf_icon_right="HandHelping"
        style="margin-top: 10px"
        :disabled="!activities && !comments"
        >Render activity feed</dsf-button
      >
    </dsf-col>
  </dsf-accordion>
</template>

<script lang="ts" setup>
import { ActivityFeed } from '@sennder/senn-node-microfrontend-interfaces'
import { ref } from 'vue'

const id = ref('e3792709-1eca-45fa-8b18-c5fe524e7992')
const name = ref('test_entity')
const title = ref('titleTranslationKey')
const root = ref('activity-feed-render-root')
const activities = ref(true)
const comments = ref(true)

const mountWithWindow = () => {
  const activityTypes: ActivityFeed.ActivityType[] = []

  if (activities.value) {
    activityTypes.push('activity')
  }

  if (comments.value) {
    activityTypes.push('comment')
  }

  window.renderActivityFeed?.(`#${root.value}`, {
    onClose: () => {
      console.log('Activity feed close callback')
    },
    titleTranslationKey: title.value,
    entity: {
      activityTypes,
      name: name.value,
      id: id.value,
    },
  })
}
</script>
