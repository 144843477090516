import { onMounted, onUnmounted, ref } from 'vue'
import { responsive } from '@sennder/design-system-core'

const { dsfDevice } = responsive

/**
 * Exposes dsfDevice signal from DSF as a Vue composable.
 */
export function useDevice() {
  const device = ref(dsfDevice.value)

  let cleanup: () => void

  onMounted(() => {
    cleanup = dsfDevice.subscribe((newDevice) => (device.value = newDevice))
  })
  onUnmounted(() => cleanup())

  return { device }
}
