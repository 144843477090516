import { logger } from '@/services/logger/loggers'
import { clearState } from '@/store/index'
import router from '@/router'
import { auth } from '@/modules/auth'
import { anonymizeTracking } from '@/services/tracking'
import { anonymizeUserInLaunchDarkly } from '@/services/launchDarkly'

export type BaseLogoutOptions = {
  callBackend?: boolean
}

export const logout = async () => {
  const isAuthenticated = await auth.value?.isAuthenticated()
  if (isAuthenticated) {
    await auth.value?.logout()
  }

  await router.push({ path: '/login' })
  clearState()
  logger.unsetUserData()

  anonymizeTracking()
  await anonymizeUserInLaunchDarkly()
}
