<template>
  <create-shipment-new v-if="isNewShipment" />
  <create-shipment-old v-else />
</template>

<script setup lang="ts">
import { getStateFeatureFlags } from '@/store'
import { computed } from 'vue'
import CreateShipmentOld from './CreateShipmentOld.vue'
import CreateShipmentNew from './CreateShipmentNew.vue'

const isNewShipment = computed(
  () => !!getStateFeatureFlags()['ENABLE_NEW_SHIPMENT_DRAFT_REDIRECT']
)
</script>
