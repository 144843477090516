<template>
  <app-layout-default v-if="layout === 'AppLayoutDefault'" />
  <app-layout-auth v-else />
</template>

<script lang="ts" setup>
import { shallowRef, watch } from 'vue'
import { useRoute } from 'vue-router'
import AppLayoutDefault from './AppLayoutDefault.vue'
import AppLayoutAuth from './AppLayoutAuth.vue'
import { getStateData } from '@/store'
import { routes } from '@/config/routes'
import { microfrontends } from '@/config/microfrontends'

const MF_WITH_CUSTOM_SCALE = [
  'marketplace',
  'chartering',
  'planner-mf-component',
  'orderDetails',
]

const route = useRoute()
type Layout = 'AppLayoutAuth' | 'AppLayoutDefault'
const layout = shallowRef<Layout>('AppLayoutAuth')

/**
 * Conditionally "reset" global styles to either Coral or Plankton depending on what micro-frontend relies on.
 */
watch(
  route,
  (newRoute) => {
    const featureFlags = getStateData().featureFlags
    if (!featureFlags['enable-chartering-mfs-senn-tms']) {
      return
    }

    const routeMeta = Object.values(routes).find(
      (route) => route.name === newRoute.name
    )
    const module = routeMeta && microfrontends[routeMeta.mf]

    const isWithCoral = module && MF_WITH_CUSTOM_SCALE.includes(module.npmName)

    const htmlElement = document.documentElement
    if (isWithCoral) {
      htmlElement.classList.remove('with-plankton')
      htmlElement.classList.add('with-coral')
    } else {
      htmlElement.classList.remove('with-coral')
      htmlElement.classList.add('with-plankton')
    }
  },
  { immediate: true }
)

watch(
  () => route.meta,
  async (meta) => {
    if (!meta.layout) {
      layout.value = 'AppLayoutDefault'
      return
    }
    layout.value = (meta.layout as Layout) || 'AppLayoutDefault'
  },
  {
    immediate: true,
  }
)
</script>
