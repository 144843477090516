import { createI18n } from 'vue-i18n'
import { App } from 'vue'

export const languages = [
  { key: 'components/language-dropdown-field-languange-en', value: 'en' },
  { key: 'components/language-dropdown-field-languange-de', value: 'de' },
]

export const defaultLanguage = 'en'

const i18n = createI18n({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
  messages: {},
})

function registerI18nPlugin(app: App) {
  app.use(i18n)
}

const translate = i18n.global.t

export { i18n, registerI18nPlugin, translate }
