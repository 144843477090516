import { CSSResultGroup, html, css, LitElement } from 'lit'
import TopMenu from '@/layouts/AppLayoutHeader/AppLayoutHeader.vue'
import NotificationsCenter from '@/layouts/AppLayoutHeader/components/NotificationsCenter.vue'
import ProfileMenu from '@/layouts/AppLayoutHeader/components/ProfileMenu.vue'
import SearchBar from '@/layouts/AppLayoutHeader/components/SearchBar.vue'
import { computed, defineCustomElement, ref, watch } from 'vue'
import { responsive } from '@sennder/design-system-core'
import { when } from 'lit/directives/when.js'
import { SignalWatcher, signal } from '@lit-labs/preact-signals'
import router from '@/router'
import { Crumb } from '@sennder/design-system-core/dist/components/molecule/Breadcrumbs'
import { i18n } from '@/services/i18n'
import { getStateData } from '@/store'

export const enableShellHeader = computed(
  () => getStateData().featureFlags['ENABLE_SHARED-HEADER'] === true
)

watch(enableShellHeader, (value) => {
  if (value) {
    shouldRenderFallbackHeader.value = true
  }
})

export const shouldRenderMobileMenu = ref<boolean>(false)
export const shouldRenderFallbackHeader = ref<boolean>(true)

export const showMobileMenu = () => {
  shouldRenderMobileMenu.value = true
}

export const hideMobileMenu = () => {
  shouldRenderMobileMenu.value = false
}

const routeName = signal(router.currentRoute.value)

watch(router.currentRoute, (newRoute) => {
  routeName.value = newRoute
})

class MFLayout extends SignalWatcher(LitElement) {
  declare mf_breadcrumbs: Array<Crumb>
  declare badge?: string
  declare fallback: boolean

  static properties = {
    mf_breadcrumbs: { type: Array },
    fallback: { type: Boolean },
    badge: { type: String },
  }

  constructor() {
    super()
    this.mf_breadcrumbs = []
    this.fallback = false
  }

  override connectedCallback() {
    super.connectedCallback()
    // If this is not a fallback (shell) header, it means we're rendered inside a microfrontend
    // and we should not render the fallback header
    if (!this.fallback) {
      shouldRenderFallbackHeader.value = false
    }
  }

  override disconnectedCallback() {
    super.disconnectedCallback()

    // If this is not a fallback (shell) header, it means we're removed from inside a microfrontend
    // and we should show the fallback header once again
    if (!this.fallback) {
      shouldRenderFallbackHeader.value = true
    }
  }

  static styles?: CSSResultGroup | undefined = [
    css`
      :host {
        max-height: 100vh;
        display: block;
        background: rgb(var(--dsf-color-neutral-0));
        border-bottom: 1px solid rgb(var(--dsf-color-neutral-100)) !important;
      }
    `,
  ]

  private onNavigate(event: CustomEvent<string>) {
    router.push(event.detail)
  }

  private getRootCrumb(): Crumb {
    const currPath = routeName.value.path.split('/')[1]
    const name = i18n.global.t(
      `navigation.item.${routeName.value.name?.toString()}`
    )
    return {
      name,
      route: currPath ? `/${currPath}` : '',
    }
  }

  renderHeader() {
    return html`
      <dsf-header>
        <dsf-row slot="breadcrumbs">
          <dsf-breadcrumbs
            @navigate=${this.onNavigate}
            .dsf_badge=${this.badge}
            .dsf_crumbs=${[this.getRootCrumb(), ...this.mf_breadcrumbs]}
          ></dsf-breadcrumbs>
          <slot name="actions"></slot>
        </dsf-row>

        <dsf-row slot="navigation" dsf_gap="xl">
          <slot name="CTA"></slot>
          <senntms-search-bar></senntms-search-bar>
          <senntms-notifications-center></senntms-notifications-center>

          ${when(
            !responsive.isMobile(),
            () => html`<senntms-profile-menu></senntms-profile-menu>`
          )}
          ${when(
            responsive.isMobile(),
            () => html` <dsf-icon-button
              dsf_icon="Menu"
              class="dsf-hidden-tablet dsf-hidden-desktop"
              dsf_size="medium"
              @click=${showMobileMenu}
            ></dsf-icon-button>`
          )}
        </dsf-row>
      </dsf-header>
    `
  }

  render() {
    if (this.fallback) {
      return this.renderHeader()
    }

    return html`
      <dsf-page-layout style="max-width: 100%; max-height: 100vh;">
        <div slot="navbar">${this.renderHeader()}</div>
        <section slot="content" style="overflow: auto;">
          <slot></slot>
        </section>
      </dsf-page-layout>
    `
  }
}

export const registerCustomElements = () => {
  customElements.define('senntms-top-menu', defineCustomElement(TopMenu))
  customElements.define(
    'senntms-notifications-center',
    defineCustomElement(NotificationsCenter)
  )

  customElements.define(
    'senntms-profile-menu',
    defineCustomElement(ProfileMenu)
  )

  customElements.define('senntms-search-bar', defineCustomElement(SearchBar))

  customElements.define('orchestrated-layout', MFLayout)
}
