<template>
  <div ref="renderRoot"></div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import { initNotifications, stopNotifications } from '@/services/notifications'

const renderRoot = ref<HTMLDivElement>()
onMounted(async () => {
  if (renderRoot.value) {
    await initNotifications(renderRoot.value)
  }
})

onUnmounted(() => {
  stopNotifications()
})
</script>
