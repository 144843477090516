<template>
  <div ref="renderRoot"></div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import { initSearchBar, stopSearchBar } from '@/services/search-bar'

const renderRoot = ref<HTMLDivElement>()
onMounted(async () => {
  if (renderRoot.value) {
    await initSearchBar(renderRoot.value)
  }
})

onUnmounted(() => {
  stopSearchBar()
})
</script>
