import { ref } from 'vue'

import { GetTokenSilentlyOptions } from '@sennder/senn-node-auth0-frontend'
import {
  SharedDataType,
  ILogProvider,
  IAction,
  IActionPermission,
  ICommonHeaders,
} from '@sennder/senn-node-microfrontend-interfaces'
import { cloneReactiveToRaw, hotModules } from '@sennder/shell-utilities'

import { logger } from '@/services/logger/loggers'
import { isLocalEnv } from '@/common/config'
import { getStateFeatureFlags } from '@/store'
import { microfrontends } from '@/config/microfrontends'

export interface ICharteringOfficeUserInfo {
  userRoleInfo: {
    uuid: string
    name: string
    legacy_id: string
    phone: string
    email: string
    role: string
  }
  charteringOfficeName: string
}

export interface AuthRemoteFunction {
  isAuthenticated: () => Promise<boolean>
  getUser: () => Promise<object | undefined>
  logout: () => Promise<void>
  getAuthToken: (options?: GetTokenSilentlyOptions) => Promise<string>
  getCommonHeaders: (audience?: string) => Promise<ICommonHeaders>
  getPermissions: (
    actions: IAction[],
    audience?: string
  ) => Promise<IActionPermission[]>
  getOrgTypeAndOrgIdOrFail: () => Promise<
    Record<'orgType' | 'orgId', string | null>
  >
  getCharteringUserRole?: (
    userUuid: string
  ) => Promise<ICharteringOfficeUserInfo>
}

interface AuthRemoteFunctionClass {
  new (
    shellType: SharedDataType,
    options: {
      logger: ILogProvider
      featureFlags: Record<string, boolean | string>
    }
  ): AuthRemoteFunction
}

export const auth = ref<AuthRemoteFunction>()

export const loadAuthRemoteFunction = async () => {
  const runHotEnv =
    isLocalEnv() && hotModules.entries.value.has(microfrontends.auth.npmName)

  if (!auth.value) {
    const { default: RemoteFunction } = await microfrontends.auth.getModule<{
      default: AuthRemoteFunctionClass
    }>('./remoteFunctions', runHotEnv ? 'hot' : undefined)

    const featureFlags = await getStateFeatureFlags()
    auth.value = new RemoteFunction(SharedDataType.SENN_TMS, {
      logger,
      featureFlags: cloneReactiveToRaw(featureFlags),
    })
  }
}
