<template>
  <dsf-header>
    <div slot="breadcrumbs">
      <dsf-text dsf_level="m-semibold" class="dsf-hidden-mobile">
        {{ headerText }}
      </dsf-text>
      <dsf-text
        dsf_level="s-semibold"
        class="dsf-hidden-tablet dsf-hidden-desktop"
      >
        {{ headerText }}
      </dsf-text>
    </div>

    <dsf-row slot="navigation" dsf_gap="m">
      <search-bar />

      <!-- Push notifications -->
      <notifications-center />

      <!-- Desktop profile -->
      <profile-menu class="dsf-hidden-mobile" />

      <!-- Mobile navigation -->
      <dsf-icon-button
        id="hamburger"
        dsf_icon="Menu"
        dsf_size="medium"
        @click="showMobileMenu"
        class="dsf-hidden-tablet dsf-hidden-desktop"
      ></dsf-icon-button>
    </dsf-row>
  </dsf-header>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import ProfileMenu from './components/ProfileMenu.vue'
import { i18n } from '@/services/i18n'
import errorsHandler from '@/services/errors-handler'
import NotificationsCenter from './components/NotificationsCenter.vue'
import SearchBar from './components/SearchBar.vue'
import router from '@/router'
import { showMobileMenu } from '@/components/orchestrated-layout'

const headerText = computed(() => {
  const currentRoute = router.currentRoute.value
  if (!currentRoute.name) {
    errorsHandler(
      `[tms-shell - AppLayoutHeader]: Route name is not defined in the route:
      ${JSON.stringify(currentRoute)}`
    )
    return
  }
  const key = `navigation.item.${(currentRoute.name as string).toLowerCase()}`
  return i18n.global.t(key)
})
</script>
