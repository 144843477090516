import { HttpClient } from './http-client/HttpClient'
import httpProvider from './http-provider'
import { getAuth0Header } from '@/services/tokenManager'
import { OPERATIONS_URL } from '@/common/config'
import { IUser } from '@sennder/senn-node-microfrontend-interfaces'
import { auth } from '@/modules/auth'

class OperationsService extends HttpClient {
  public async getUser(): Promise<IUser> {
    const backendUser: IUser = await this.get('/accounts/users/me')
    const authUser = await auth.value?.getUser()

    // TODO: need to define an interface for user response from auth0
    // @ts-ignore
    backendUser.picture = authUser?.picture
    // @ts-ignore
    backendUser.uuid = authUser?.uuid
    return backendUser
  }

  public async changeUserLanguage(language: string): Promise<void> {
    return this.post('/accounts/commands/change-user-language', {
      data: { language },
    })
  }
}

if (!OPERATIONS_URL) {
  throw new Error('OPERATIONS_URL not found')
}

export const operationsService = new OperationsService({
  httpProvider,
  getAuthHeader: getAuth0Header,
  baseURL: OPERATIONS_URL,
})
