import { loadState } from '@/store'
import { identifyUserInTracking } from '@/services/tracking'

export const loadUserData = async (onLogin = false) => {
  if (!(await loadState())) {
    return
  }

  identifyUserInTracking(onLogin)
}
