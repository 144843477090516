import { TRANSLATIONS_URL } from '@/common/config'
import { TranslationProvider } from '@sennder/shell-utilities'
import { logger } from '@/services/logger/loggers'
import { i18n } from '@/services/i18n'
import { setStoreLanguage } from '@/store'

export const translationProvider = new TranslationProvider({
  afterTranslationChanged: (lang, translations) => {
    i18n.global.setLocaleMessage(lang, translations)
    i18n.global.locale = lang
    setStoreLanguage(lang)
  },
  cloudServiceUrl: TRANSLATIONS_URL,
  defaultLanguage: 'en',
  supported: ['en', 'de'],
  loadLocalTranslation: (lang) =>
    import(
      /* webpackChunkName: "chunk.i18n-[request]" */ `../languages/${lang}.json`
    ),
  logger,
})
