import {
  GetTokenOptions,
  ILogProvider,
} from '@sennder/senn-node-microfrontend-interfaces'

export const createRandomString = () =>
  Math.random()
    .toString(36)
    .substring(4)
    .replace(/[^a-zA-Z]+/g, '')

export const convertToKebabCase = (value: string): string => {
  return value
    .replace(/[A-Z]/g, function (match) {
      return '-' + match.toLowerCase()
    })
    .replace(/^-/, '')
}

export const wrapAuthWithLogging = (
  func: (options?: GetTokenOptions) => Promise<string | null>,
  logger: ILogProvider
) => {
  const wrappedFunction: (
    options?: GetTokenOptions
  ) => Promise<string | null> = async (...args) => {
    logger.info('[Legacy Auth Attempt]', {
      args,
    })
    return await func(...args)
  }

  return wrappedFunction
}
