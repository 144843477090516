<template>
  <dsf-nav-menu
    :dsf_name="userName"
    :dsf_role="roleName"
    :dsf_mobile="mobileMode"
    :dsf_profile_url="userPicture"
  >
    <!-- Language selector -->
    <dsf-navbar-item
      v-if="SHOW_LANGUAGE_PICKER"
      dsf_light
      dsf_icon="Globe"
      :dsf_title="translate('profile.language')"
      dsf_id="language"
      @navigate.stop="onLanguageChange"
    >
      <dsf-navbar-item
        v-for="lang in languages"
        :key="lang.key"
        :dsf_title="translate(lang.key)"
        :dsf_selected="lang.value === translationProvider.currentLanguage"
        :dsf_id="lang.value"
      ></dsf-navbar-item>
    </dsf-navbar-item>
    <dsf-navbar-item
      v-if="userHaveCMRole()"
      dsf_light
      dsf_icon="Link"
      :dsf_title="translate('profile.copy-onboarding-link')"
      dsf_id="copyOnboardingLink"
      @navigate.stop="onCopyOnboardingLink"
    ></dsf-navbar-item>

    <!-- Logout -->
    <dsf-navbar-item
      dsf_light
      dsf_icon="LogOut"
      :dsf_title="translate('profile.logout')"
      dsf_id="logout"
      @navigate.stop="onLogout"
    ></dsf-navbar-item>
  </dsf-nav-menu>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { logout } from '@/store/logoutActions'
import { getStateData } from '@/store'
import { languages, translate } from '@/services/i18n'
import { operationsService } from '@/services/operationsService'
import { translationProvider } from '@/services/translationProvider'
import { ORCAS_URL, SHOW_LANGUAGE_PICKER } from '@/common/config'
import { notify } from '@/store/notify'

defineProps({
  mobileMode: Boolean,
})

const emit = defineEmits(['select-language'])

const userPicture = computed(() => getStateData().user?.picture || null)

const userName = computed(
  () => getStateData().user?.fullNameWithoutTitle || 'Unknown name'
)

const roleName = computed(() => {
  return (
    getStateData()
      .user?.roles?.map((role) => role.name)
      .join(' | ') || 'Unknown role'
  )
})

const onCopyOnboardingLink = async (): Promise<void> => {
  const userUUID = getStateData().user?.uuid
  if (userUUID) {
    const link = `${ORCAS_URL}/signup?referral_id=${userUUID}&utm_source=internal_referral&utm_medium=internal_referral&utm_campaign=internal_referral`
    navigator.clipboard.writeText(link)
    notify('Link copied !', 'success')
  }
}
const userHaveCMRole = () => {
  const roles = getStateData().user?.roles
  if (roles) {
    for (const role of roles) {
      if (role.name?.endsWith('CM')) {
        return true
      }
    }
  }
  return false
}
async function onLanguageChange(evt: Event) {
  const { detail } = evt as CustomEvent<string>
  translationProvider.set(detail, {
    updated: async () => {
      await operationsService.changeUserLanguage(detail)
      emit('select-language')
    },
  })
}

async function onLogout() {
  await logout()
}
</script>
