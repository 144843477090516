import { amplitude } from '@sennder/senn-node-analytics-frontend'
import { AmplitudeLogLevel } from '@sennder/senn-node-analytics-frontend/lib/types'
import { AppAmplitudeLogger } from '@sennder/shell-utilities'

import { AMPLITUDE_API_KEY, TENANT, isUatEnv } from '@/common/config'
import { getStateData, getStateUser } from '@/store'
import { executeSafelyWithLogging } from './errors-handler'
import { logger } from './logger/loggers'

export function initAnalytics() {
  return executeSafelyWithLogging(() => {
    if (isUatEnv()) {
      logger.warn(
        '[tms-shell - Analytics]: Amplitude is not available in UAT environment',
        {}
      )
      return
    }
    if (!AMPLITUDE_API_KEY) {
      throw new Error('[tms-shell - Analytics]: AMPLITUDE_API_KEY not found')
    }
    amplitude.init({
      token: AMPLITUDE_API_KEY,
      appName: 'tms',
      amplitudeConfig: {
        minIdLength: 3,
        loggerProvider: new AppAmplitudeLogger(logger),
        logLevel: AmplitudeLogLevel.Warn,
      },
    })
  }, 'amplitude.init')
}

export function identifyUserInAnalytics(onLogin: boolean) {
  const analyticsFeaturesFlags = Object.fromEntries(
    Object.entries(getStateData().featureFlags).filter(([flag]) =>
      flag.startsWith('exp')
    )
  )
  const user = getStateUser()

  const analyticsUser = {
    // TODO: Define custom user properties
    tenant: TENANT,
    userId: user.id.toString(),
    userName: user.username,
    language: user.language,
    ...analyticsFeaturesFlags,
  }

  executeSafelyWithLogging(
    () =>
      // TODO: Define custom group properties
      amplitude.identifyUser(analyticsUser, onLogin, {
        groupType: 'tms id',
        groupValue: 'tms group value',
      }),
    'amplitude.identifyUser'
  )
}

export const resetAnalyticsSession = () => {
  executeSafelyWithLogging(() => amplitude.endSession(), 'amplitude.endSession')
  initAnalytics()
}

// Used when the user rejects analytics cookies,
// all user data will be removed from the analytics integration as well as any event submissions will be terminated
export const stopAnalytics = () => {
  executeSafelyWithLogging(
    () => amplitude.stopTracking(),
    'amplitude.stopTracking'
  )
}
