import { Navigation } from '@sennder/shell-utilities'

import CreateShipment from '@/layouts/components/CreateShipment.vue'
import { AppRoute } from './routes'
import {
  canAccessCharteringPages,
  canAccessClaimsBoard,
  canAccessFinanceDashboard,
  canAccessMyCarriers,
  canAccessAnalysisBoard,
} from './user-access'

export type AppNavigation = Navigation<AppRoute>

/**
 * Main menu items: categories, leafs (links) and widgets
 */
export const navigation: AppNavigation[] = [
  {
    kind: 'widget',
    component: CreateShipment,
    icon: 'Plus',
    translationKey: '',
    dataTest: 'create-shipment',
  },
  {
    kind: 'leaf',
    route: '/dashboard/overview',
    icon: 'Search',
    translationKey: 'search-board',
    dataTest: 'search-board',
  },
  {
    kind: 'leaf',
    route: '/single-view',
    icon: 'Eye',
    translationKey: 'single-view',
    dataTest: 'single-view',
  },
  {
    kind: 'leaf',
    route: '/analysis',
    icon: 'custom:analysis',
    translationKey: 'analysis-management',
    visibilityHandlers: [canAccessAnalysisBoard],
  },
  {
    kind: 'leaf',
    route: '/dashboard/dispatching',
    icon: 'custom:dispatching',
    translationKey: 'dispatching-board',
    dataTest: 'dispatching-board',
  },
  {
    kind: 'leaf',
    route: '/dashboard/es-execution',
    icon: 'Radar',
    translationKey: 'es-execution-board',
    dataTest: 'execution-board',
  },
  {
    kind: 'leaf',
    route: '/dashboard/finance',
    icon: 'Euro',
    translationKey: 'finance-dashboard',
    visibilityHandlers: [canAccessFinanceDashboard],
    dataTest: 'finance-dashboard',
  },
  {
    kind: 'leaf',
    route: '/contract-management',
    icon: 'FileText',
    translationKey: 'contract-management',
    dataTest: 'contract-management',
  },
  {
    kind: 'leaf',
    route: '/finance/claims-board',
    icon: 'custom:claims-board',
    translationKey: 'claims-board',
    visibilityHandlers: [canAccessClaimsBoard],
    dataTest: 'claims-board',
  },
  {
    kind: 'leaf',
    route: '/my-carriers',
    icon: 'BookUser',
    translationKey: 'my-carriers',
    visibilityHandlers: [canAccessMyCarriers],
    dataTest: 'my-carriers',
  },
  {
    kind: 'leaf',
    route: '/spotter',
    icon: 'Compass',
    translationKey: 'spotter',
    dataTest: 'spotter',
  },
  {
    kind: 'category',
    icon: 'Truck',
    translationKey: 'carriers',
    dataTest: 'carriers',
    children: [
      {
        kind: 'leaf',
        route: '/carriers',
        translationKey: 'carrier-search',
        dataTest: 'carrier-search',
      },
      {
        kind: 'leaf',
        route: '/vetting-dashboard',
        translationKey: 'vetting-dashboard',
        dataTest: 'vetting-dashboard',
      },
      {
        kind: 'leaf',
        route: '/blocklisting-dashboard',
        translationKey: 'blocklisting-dashboard',
        dataTest: 'blocklisting-dashboard',
      },
    ],
  },
  {
    kind: 'leaf',
    route: '/facilities',
    icon: 'Building',
    translationKey: 'facility-management',
    dataTest: 'facility-management',
  },
  {
    kind: 'leaf',
    route: '/senn-teams',
    icon: 'Users',
    translationKey: 'senn-teams',
    dataTest: 'senn-teams',
  },
  {
    kind: 'category',
    icon: 'GanttChart',
    translationKey: 'chartering',
    dataTest: 'chartering',
    children: [
      {
        kind: 'leaf',
        route: '/planner',
        icon: 'Calendar',
        translationKey: 'planner',
        dataTest: 'planner',
        visibilityHandlers: [canAccessCharteringPages],
      },
      {
        kind: 'leaf',
        route: '/contracts',
        icon: 'FileText',
        translationKey: 'contracts',
        dataTest: 'contracts',
        visibilityHandlers: [canAccessCharteringPages],
      },
      {
        kind: 'leaf',
        route: '/marketplace',
        icon: 'custom:marketplace',
        translationKey: 'marketplace',
        dataTest: 'marketplace',
        visibilityHandlers: [canAccessCharteringPages],
      },
    ],
  },
]
