<template>
  <dsf-navbar
    dsf_mobile
    :dsf_title="$t('navigation.title.expanded')"
    @navigate="navigate"
    @close="closeMobileSidebar"
    @logo-click="onLogoClick"
    class="nav-bar"
  >
    <!-- Page navigation items -->
    <app-navigation-items />

    <!-- Profile menu items -->
    <profile-menu
      slot="footer"
      :mobile-mode="true"
      @close.stop
      @select-language="closeMobileSidebar"
      class="mt-auto"
    />
  </dsf-navbar>
</template>

<script lang="ts" setup>
import router from '@/router'
import AppNavigationItems from './AppNavigationItems.vue'
import ProfileMenu from './AppLayoutHeader/components/ProfileMenu.vue'

const emit = defineEmits(['close'])

function closeMobileSidebar() {
  emit('close')
}

const navigate = (e: CustomEvent<string>) => {
  const route = e.detail
  if (route) {
    router.push({ path: route })
    window.scrollTo(0, 0)
  }
  closeMobileSidebar()
}

const onLogoClick = () => {
  router.push({ path: '/' })
  closeMobileSidebar()
}
</script>

<style scoped lang="scss">
.nav-bar {
  z-index: 15; // override Octopus Core z-index
}
</style>
