import { SharedDataType } from '@sennder/senn-node-microfrontend-interfaces'
import { Fullstory, Hotjar } from '@sennder/shell-utilities'

import {
  identifyUserInAnalytics,
  initAnalytics,
  resetAnalyticsSession,
  stopAnalytics,
} from './analytics'
import {
  FULLSTORY_ORGID,
  isLocalEnv,
  isUatEnv,
  HOTJAR_ID,
  isProductionEnv,
} from '@/common/config'

import { logger } from '@/services/logger/loggers'
import { identifyUserInLogger, initLogger, stopLogger } from '@/services/logger'
import {
  identifyUserInMonitor,
  initMonitor,
  stopMonitor,
} from '@/services/monitor'
import { getStateUser } from '@/store'

const fullstory = new Fullstory(
  FULLSTORY_ORGID,
  !isLocalEnv() && !isUatEnv(),
  isLocalEnv(),
  logger.error
)

let state: 'stopped' | 'started' = 'stopped'

export function startTracking() {
  if (state === 'started') {
    return
  }

  initLogger()
  initMonitor()
  initAnalytics()
  fullstory.init()

  if (isProductionEnv() && HOTJAR_ID) {
    const hotjar = new Hotjar(console, HOTJAR_ID)
    // This is an async func, but we don't want to wait for startTracking since it would block the entire page rendering
    hotjar.inject()
  }

  state = 'started'

  log('tracking has started')
}

export function identifyUserInTracking(onLogin = false) {
  if (state === 'stopped') {
    return
  }
  const user = getStateUser()

  identifyUserInAnalytics(onLogin)
  identifyUserInLogger(String(user.id), user.username)
  identifyUserInMonitor(String(user.id), user.username)
  fullstory.identify(SharedDataType.SENN_TMS, user.id, user.email)

  log('user is identified')
}

export function anonymizeTracking() {
  if (state === 'stopped') {
    return
  }
  resetAnalyticsSession()
  fullstory.anonymize()

  log('user is anonymized')
}

export function stopTracking() {
  stopAnalytics()
  stopMonitor()
  stopLogger()
  fullstory.stop()
  state = 'stopped'

  log('tracking has stopped')
}

function log(message: string) {
  const prefix = '[tracking]'
  logger.info(`${prefix} ${message}`, {})
  console.log(`${prefix} ${message}`)
}
