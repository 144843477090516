import FetchProvider from './http-client/providers/FetchProvider'
import { snakeToCamel } from '@/services/http-client/transformers/snakeToCamel'
//import { logout } from '@/store/logoutActions'
import { logger } from '@/services/logger/loggers'

//TODO: Add tenants for env variables
const httpProvider = new FetchProvider()

httpProvider
  .addInterceptor({
    onError: async (status) => {
      //status can be undefined if error is related to fetch API Authorization header:
      // incorrect value or cyrillic in token
      // https://stackoverflow.com/questions/49109878/typeerror-failed-to-execute-fetch-on-window-invalid-value
      if (status === 401) {
        // TODO: uncomment when all endpoints are ready. Do not log anything
        // await logout()
        logger.warn('401 response received. TODO: Logging out...', { status })
      }
    },
  })
  .addResponseTransformer(snakeToCamel)

export default httpProvider
