import {
  MicrofrontendEvents,
  onDispatchToastNotification,
  publish,
} from '@sennder/senn-node-microfrontend-event-bus'

import { isLocalEnv } from '@/common/config'
import { logger } from '@/services/logger/loggers'
import { i18n } from '@/services/i18n'
import { sendErrorInMonitor } from '@/services/monitor'

export type INotification = {
  message: string
  category: onDispatchToastNotification['category']
}

type IErrorWithNotification = {
  notification: INotification
}

export function executeSafelyWithLogging<T extends (...args: any[]) => any>(
  callback: T,
  callbackName: string
) {
  try {
    callback()
  } catch (error) {
    logger.error(`[tms] Error while executing ${callbackName}: ${error}`, {
      error,
    })
  }
}

export default (error: any, message = '') => {
  const notificationMessage =
    (error as IErrorWithNotification).notification?.message || null

  if (notificationMessage) {
    const notificationCategory =
      (error as IErrorWithNotification).notification?.category || 'error'

    publish<onDispatchToastNotification>({
      name: MicrofrontendEvents.onDispatchToastNotification,
      data: {
        message: notificationMessage,
        category: notificationCategory,
        title: i18n.global.t('toast.error.title'),
      },
    })
  }

  if (isLocalEnv()) {
    publish<onDispatchToastNotification>({
      name: MicrofrontendEvents.onDispatchToastNotification,
      data: {
        message: `Check the console log for details. This message won't be displayed in production. ${message}`,
        category: 'error',
        title: i18n.global.t('toast.error.title'),
      },
    })
    console.error(error, message)
  } else {
    sendErrorInMonitor(error, {
      message,
    })
  }
}
